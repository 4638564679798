



















































import Vue from 'vue';
import IVertragsfaten from '@/model/IVertragsfaten';

export default Vue.extend({
  name: 'vertragsdaten',
  props: {
    paramsVertragsdaten: Object,
  },
  watch: {
    paramsVertragsdaten(value: IVertragsfaten) {
      this.$emit('params-vertragsdaten-change', value);
    },
  },
  data() {
    return {
      vertragsdatenOpen: true,
    };
  },
});
